import React from "react";

const Terms = () => {
  return (
    <div style={{ padding: "1rem calc(-600px + 51vw)" }}>
      <header>
        <h1>Terms and Conditions</h1>
      </header>
      <h2>Terms and Conditions for Dhuniya App </h2>
      <p>
        These Terms and Conditions ("Agreement") are entered into by and between
        you ("User" or "you") and Dhuniya App ("Company," "we," or "us"). This
        Agreement governs your use of the Dhuniya App platform, including but
        not limited to accessing short news, business listings, and video
        streaming services.
      </p>
      <p>
        {" "}
        By accessing or using the Dhuniya App, you agree to be bound by these
        Terms and Conditions. If you do not agree to these terms, please do not
        use the app.
      </p>
      <h4>1.Acceptance of Terms:</h4>
      <ul>
        <li>
          By using Dhuniya App, you acknowledge that you have read, understood,
          and agree to comply with these Terms and Conditions. We reserve the
          right to update, modify, or replace any part of these terms. Your
          continued use of the app after any such changes constitutes your
          acceptance of the new terms.
        </li>
      </ul>
      <h4>2. User Accounts:</h4>
      <ul>
        <li>
          To access certain features of Dhuniya App, you may be required to
          create a user account. You agree to provide accurate, current, and
          complete information during the registration process and to update
          such information to keep it accurate, current, and complete.
        </li>
      </ul>
      <h4>3.Content Usage:</h4>
      <ul>
        <h4> (a) Short News:</h4>
        <li>
          Dhuniya App provides short news articles for informational purposes
          only. The Company does not endorse or guarantee the accuracy,
          completeness, or reliability of any news content.
        </li>
        <h4>(b) Business Listings:</h4>
        <li>
          Business information on Dhuniya App is provided by third-party
          sources. The Company is not responsible for the accuracy or
          completeness of business listings. Users are advised to verify
          information independently.
        </li>
        <h4>(c) Video Streaming:</h4>
        <li>
          The video streaming service on Dhuniya App is for personal,
          non-commercial use. Users agree not to reproduce, distribute, modify,
          or otherwise exploit the content without the
        </li>
      </ul>
      <h4>4. User Conduct:</h4>
      You agree not to engage in any of the following activities while using
      Dhuniya App:
      <br />
      <br />
      <ul>
        <li>(a) Violating any applicable laws or regulations.</li>
        <li>(b) Interfering with the proper functioning of the app.</li>
        <li>(d) Harassing, abusing, or harming other users.</li>
        <li>
          (c) Uploading or transmitting malicious code, viruses, or any other
          harmful data.
        </li>
      </ul>
      <h4>5.Privacy Policy:</h4>
      Our Privacy Policy outlines how we collect, use, and disclose personal
      information. By using Dhuniya App, you agree to the terms of our Privacy
      Policy.
      <h4>6.Termination:</h4>
      The Company reserves the right to terminate or suspend your account and
      access to Dhuniya App at its sole discretion, without prior notice, for
      any reason, including violation of these Terms and Conditions.
      <h4>7.Limitation of Liability:</h4>
      To the extent permitted by law, the Company shall not be liable for any
      direct, indirect, incidental, special, or consequential damages resulting
      from the use or inability to use Dhuniya App.
      <h4>8.Governing Law:</h4>
      These Terms and Conditions shall be governed by and construed in
      accordance with the laws of Telangana.
      <br />
      <br />
      <p>
        By using Dhuniya App, you agree to abide by these Terms and Conditions.
        If you have any questions or concerns, please contact us at
        support@dhuniya.in.
      </p>
      <p>Date of last update: 23/01/2024</p>
      <p> Dhuniya</p>
    </div>
  );
};

export default Terms;
