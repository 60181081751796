import React from "react";
import "@fontsource/lexend"; // Defaults to weight 400
import "./App.css";
import Navbar from "./components/Navbar";
import Footer from "./components/footer";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import News from "./pages/news";
import Ott from "./pages/ott";
import FullVideo from "./pages/ott/ottMain/videos/fullVideo";
import OttPlayer from "./pages/ott/ottPlayer";
import FindBusiness from "./pages/find_business";
import VBC from "./pages/vbc";
import Privacy from "./pages/private";
import Terms from "./pages/terms";

import FullArticle from "./pages/news/forYou/articles/fullArticle";

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<News />} />
          <Route path="/news">
            <Route path=":article_id" element={<FullArticle />} />
            <Route path="" element={<News />} />
          </Route>
          <Route path="/ott">
            <Route path=":video_id" element={<FullVideo />} />
            <Route path="" element={<Ott />} />
          </Route>
          <Route path="/ottPlayer" element={<OttPlayer />} />
          <Route path="/fb" element={<FindBusiness />} />
          <Route path="/vbc/:ref_id" element={<VBC />} />
          <Route path="/privacy.html" element={<Privacy />} />
          <Route path="/terms.html" element={<Terms />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
